import React from "react";
import {Grid} from "@mui/material"
import PurchaseStepper from "../components/PurchaseStepper";

export default function App() {

  return (
    <Grid>
      <PurchaseStepper/>
    </Grid>
  );
}