import * as React from 'react';
import { AppBar, Box, Grid, Toolbar } from "@mui/material";

export default function NavAppBar() {


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: 'black', textTransform: 'none' }}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >

          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


