import * as React from "react"

const FileBrowserContext = React.createContext(undefined);

export const FileBrowserProvider = ({ children }) => {

  const [files, setFiles] = React.useState([]);
  const [showDir, setShowDir] = React.useState(false);

  const getFiles = () => {
    let files = []
    window.walletDb.openCursor('.', (item) => {
      if(item?.name){
        files.push(item);
      }
    }).then(() => {
      setFiles(files)
    })
  }

  return (<FileBrowserContext.Provider value={{
    files,
    setFiles,
    showDir, setShowDir,
    getFiles
  }}>{children} </FileBrowserContext.Provider>)
};

export default FileBrowserContext;

