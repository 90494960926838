import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const { onPayment } = props;
  const buttonSx = {
    ...(succeeded && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    if (succeeded && paymentIntentId !== '') {
      onPayment(paymentIntentId)
    }
  }, [succeeded, paymentIntentId, onPayment])

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setPaymentIntentId(payload.paymentIntent.id)
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <Grid
      container
      direction="row"
      sx={{ flexGrow: 1, width: '100%' }}
    >
      <Grid xs={10} sx={{ pt: 4 }} item>
        <form id="payment-form">
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
          <Button
            disabled={processing || disabled || succeeded}
            fullWidth
            variant="contained"
            id="submit"
            onClick={handleSubmit}
            sx={buttonSx}
          >

            {processing ? (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            ) : null}
            {succeeded ? (<DoneIcon
              sx={{
                fontSize: 24,
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '95%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }} />) : null}
            Pay now
          </Button>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <Typography sx={{ mt: 1 }} color={'error'} variant="body2">
              {error}
            </Typography>
          )}
          {/* Show a success message upon completion */}
          {succeeded ?
            <Typography sx={{ mt: 1 }} color={'success'} variant="body2">
              Payment succeeded!
            </Typography> : null

          }

        </form>
      </Grid>
    </Grid >
  );
}