import * as React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import NotificationContext from "../contexts/NotificationContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/Stripe/CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51LhkVdIX0yvaTuKfzvCARA3U5q5anc0BVIpuxHip2Fw5DUIdDmWHT89NZSlbdREVd4RmXs5rtsQeRcz5rKK0EDtV004ORh8d0C");

export default function VerticalLinearStepper() {
    const notify = React.useContext(NotificationContext)
    const [activeStep, setActiveStep] = React.useState(0);
    const [clientSecret, setClientSecret] = React.useState("");
    const [paymentIntentId, setPaymentIntentId] = React.useState("");
    const [storageId, setStorageId] = React.useState("");
    const [quantity, setQuantity] = React.useState(50);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (submitting) {
            try {
                fetch(process.env.REACT_APP_STORE_SERVER + "/create-payment-intent", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ quantity: quantity }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        handleNext()
                        setClientSecret(data.clientSecret)
                    });
            } catch (e) {
                console.error(e)
                notify.setMessage('An error occurred when submitting your request');
                notify.setSeverity('error');
                notify.setOpen(true)
            }
        }

    }, [submitting, notify, quantity])

    React.useEffect(() => {
        if (activeStep === 0) {
            setSubmitting(false)
        }
    }, [activeStep])

    const updateValue = (e) => {
        if (e.target.value >= 50) {
            setQuantity(e.target.value)
        }
    }

    const submitIfEnter = (e) => {
        if (e.keyCode === 13) {
            submit();
        }
    }
    const submit = () => {
        setSubmitting(true)
    }
    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#508ac3',
            colorBackground: '#FFF',
            colorText: '#323777',
        },
    };
    const options = {
        clientSecret,
        appearance,
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onPayment = (id) => {
        setPaymentIntentId(id)
        fetch(process.env.REACT_APP_STORE_SERVER + "/consume-payment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: id })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data.error) {
                    notify.setMessage(data.error)
                    notify.setSeverity("error")
                    notify.setOpend(true)
                } else {
                    setStorageId(data.storage_id);
                    handleNext();
                }
            });
    }

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                    <StepLabel>
                        {'Select Amount'}
                    </StepLabel>
                    <StepContent>
                        <Typography variant={'body1'}>
                            How much storage do you need?
                        </Typography>
                        <Typography variant={'body2'}>
                            Storage is pay as you go, no monthly subscription.
                        </Typography>
                        <Grid style={{ width: 500 }}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Grid item xs={10} md={6} sx={{ pb: 2, pt: 2 }}>
                                <TextField
                                    type={"number"}
                                    id={'token-quantity'}
                                    placeholder="Token Quantity"
                                    value={quantity}
                                    fullWidth
                                    onKeyDownCapture={submitIfEnter}
                                    onChange={updateValue}
                                />
                            </Grid>
                            <Grid item md={1} xs={0} />
                            <Grid item xs={10} md={5}>
                                <Typography variant={'body2'} sx={{ textAlign: { md: 'center' } }}>
                                    {'Each unit is a gigabyte (GB) and costs $0.20 USD'}
                                </Typography>
                                <Typography variant={'body2'} sx={{ textAlign: { md: 'center' } }}>
                                    {`${quantity}GB for $${(Math.round(quantity * 20) / 100).toFixed(2)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{ mb: 2 }}>
                            <div>
                                <Button
                                    disabled={submitting}
                                    variant="contained"
                                    onClick={submit}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    {'Continue'}
                                </Button>
                            </div>
                        </Box>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>
                        {'Pay'}
                    </StepLabel>
                    <StepContent>
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm onPayment={onPayment} clientSecret={clientSecret} />
                            </Elements>
                        )}
                        <Box sx={{ mb: 2 }}>
                            <div>
                                <Button
                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Back
                                </Button>
                            </div>
                        </Box>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel >
                        {'Enjoy'}
                    </StepLabel>
                    <StepContent>
                        {/* <Box sx={{ mb: 2 }}>
                            <div>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    {'Finish'}
                                </Button>
                                <Button

                                    onClick={handleBack}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Back
                                </Button>
                            </div>
                        </Box> */}

                    </StepContent>
                </Step>
            </Stepper>
            {activeStep > 1 && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography variant='h6'>Congratulations your purchase was succesful!</Typography>
                    <Typography variant='body1'>Your transaction ID is {paymentIntentId}</Typography>
                    <Typography variant='body1'>Your storage ID is {storageId}</Typography>
                    <Button href={`${process.env.REACT_APP_WALLET_URL}?deposit=${storageId}`} sx={{ mt: 1, mr: 1 }} component={'a'}>
                        Deposit in Wallet
                    </Button>
                </Paper>
            )}
        </Box>
    );
}